import React from 'react';
import {
  ForgotPasswordModal,
  StageInviteModal,
  StageModal,
  TipModal,
  PaymentModal,
  PurchaseThankModal,
  FollowModal,
  BuyLiveCoinModal,
  NewPaymentModal,
} from '~components/organisms/popup';
import {
  FEATURE_FLAGS_TYPE,
  POPUP_TYPE,
  useGlobalDispatch,
  useGlobalState,
  USER_TYPE,
} from '~utils';
import LoginPopup from '~components/popup/login';
import ProfilePopup from '~components/popup/profile-photo';
import WelcomePopup from '~components/popup/welcome';
import RewardModal from '~components/organisms/popup/reward-modal';
import { NotificationModalWindow } from '~components/atoms';
import BookingProducerModal from '~components/organisms/popup/booking-producer-modal';

import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import ChangePasswordModal from '~components/organisms/popup/change-password-modal';
import ChangeUsernameModal from '~components/organisms/popup/change-username-modal';
import ChangeEmailModal from '~components/organisms/popup/change-email-modal';
import BecomePartnershipModal from '~components/organisms/popup/become-partnership-modal';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import UpdatePhoneNumberModal from '../popup/update-phone-number-modal';
import SelectGenreModal from '../popup/select-genre';
import UploadAvatarPopup from '../popup/new-upload-avatar';
import GettingStartedModal from '../popup/getting-started';

const MenuPopupsContainer = () => {
  const state = useGlobalState();
  const { isEnable } = useFeatureFlag();
  const { hidePopup } = useDispatchPopup();
  const {
    app: { popup },
    user: { type },
  } = state;

  const dispatch = useGlobalDispatch();
  const oncloseWelcomeNotification = () => {
    dispatch({
      type: 'app',
      payload: {
        popup:
          type === USER_TYPE.ARTIST
            ? POPUP_TYPE.BOOKING_PRODUCER
            : POPUP_TYPE.NONE,
      },
    });
  };

  const isEnableNewPayment = isEnable(
    FEATURE_FLAGS_TYPE.NEW_PAYMENT_FLOW_FOR_TAX,
  );

  return (
    <>
      {popup === POPUP_TYPE.LOGIN && <LoginPopup />}
      {popup === POPUP_TYPE.SIGN_UP && <LoginPopup openSignUpTab={true} />}
      {popup === POPUP_TYPE.FORGOT_PWD && <ForgotPasswordModal />}
      {(popup === POPUP_TYPE.PROFILE ||
        popup === POPUP_TYPE.EDIT_PROFILE_POPUP) && <ProfilePopup />}
      {popup === POPUP_TYPE.STAGE_INVITE && <StageInviteModal />}
      {popup === POPUP_TYPE.STAGE && <StageModal />}
      {popup === POPUP_TYPE.WELCOME_POPUP && <WelcomePopup />}
      {popup === POPUP_TYPE.TIP && <TipModal />}
      {popup === POPUP_TYPE.PAYMENT_METHODS &&
        (!isEnableNewPayment ? <PaymentModal /> : <NewPaymentModal />)}
      {popup === POPUP_TYPE.THANK_POPUP && <PurchaseThankModal />}
      {popup === POPUP_TYPE.FOLLOW && <FollowModal onClose={hidePopup} />}

      {popup === POPUP_TYPE.REWARDS && <RewardModal />}
      {popup === POPUP_TYPE.BUY_LC_POPUP && <BuyLiveCoinModal />}
      {popup === POPUP_TYPE.WELCOME_NOTIFICATION && (
        <NotificationModalWindow
          open={true}
          onClose={oncloseWelcomeNotification}
          titleUnderImage={type === USER_TYPE.ARTIST ? 'SUCCESS!' : ''}
          title={'Welcome to Ursa Live!'}
        />
      )}

      {popup === POPUP_TYPE.BOOKING_PRODUCER && <BookingProducerModal />}
      {popup === POPUP_TYPE.CHANGE_PASSWORD && <ChangePasswordModal />}
      {popup === POPUP_TYPE.CHANGE_USERNAME && <ChangeUsernameModal />}
      {popup === POPUP_TYPE.CHANGE_EMAIL && <ChangeEmailModal />}
      {popup === POPUP_TYPE.BECOME_PARTNERSHIP && <BecomePartnershipModal />}
      {popup === POPUP_TYPE.UPDATE_PHONE_NUMBER && <UpdatePhoneNumberModal />}
      {popup === POPUP_TYPE.SELECT_GENRE && <SelectGenreModal />}
      {popup === POPUP_TYPE.UPLOAD_AVATAR && <UploadAvatarPopup />}
      {popup === POPUP_TYPE.GETTING_STARTED && <GettingStartedModal />}
      {popup === POPUP_TYPE.NEW_WELCOME_NOTIFICATION && (
        <NotificationModalWindow
          open={true}
          onClose={() => hidePopup()}
          title={type === USER_TYPE.ARTIST ? 'SUCCESS!' : ''}
          description={'Welcome to Ursa Live!'}
        />
      )}
    </>
  );
};

export default MenuPopupsContainer;
