import { useState } from 'react';
import { IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import BasePopup from '../base-popup';
import { FillPhoneNumberStep } from './fill-phone-number-step';
import { OtpCodeStep } from './otp-code-step';

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const StyledUpdatePhoneNumberModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 638,
  },
}));

const UpdatePhoneNumberModal = () => {
  const [isDoneStep1, setIsDoneStep1] = useState(false);
  const { hidePopup } = useDispatchPopup();
  const [phoneDetail, setPhoneDetail] = useState<any>();
  const { showSelectGenrePopup } = useDispatchPopup();

  const handleClose = () => {
    hidePopup();
    showSelectGenrePopup();
  };

  return (
    <StyledUpdatePhoneNumberModal
      open={true}
      onClose={handleClose}
      closeBtn={
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      {!isDoneStep1 ? (
        <FillPhoneNumberStep
          onDoneRequestVerifying={(
            countryCode: string,
            phoneNumber: string,
          ) => {
            setPhoneDetail({
              phoneNumber: phoneNumber,
              countryCode: countryCode,
            });
            setIsDoneStep1(true);
          }}
        />
      ) : (
        <OtpCodeStep phoneDetail={phoneDetail} />
      )}
    </StyledUpdatePhoneNumberModal>
  );
};

export default UpdatePhoneNumberModal;
