import React from 'react';

import {
  Box,
  Divider,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';

import { addMinutes, formatDateTime } from '~utils/dateTimeUtils';
import {
  DEFAULT_EVENT_BUFFER_TIME,
  SHORT_DATE_TIME_WITH_TIME_ZONE,
} from '~utils/constants';
import AddToCalendarButton from '../add-to-calendar';

import { ArtistEvent } from '~types/event';

const StyledCard = styled(Box)<{ active?: boolean }>(({ theme, active }) => ({
  '&:hover': {
    // TODO: update style when active
    cursor: active ? 'auto' : 'pointer',
  },
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.primary.lighter,
}));

const StyledCalendarBtnGroup = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  '& >:not(style)+:not(style)': {
    margin: 0,
    marginLeft: 12,
  },
}));

type EventCardProps = {
  data: ArtistEvent;
  calendarBtnGroupProps?: StackProps;
};

const EventCard = ({
  data: { event },
  calendarBtnGroupProps,
}: EventCardProps) => {
  const startTime = event?.scheduledAt;
  const endTime =
    event?.eventBufferTime || addMinutes(startTime, DEFAULT_EVENT_BUFFER_TIME);
  let eventName = event?.name;

  const regex = /Live with (.+?) on URSA Live!/;
  const match = eventName.match(regex);

  if (match && match[1]) {
    eventName = `${match[1]}: Live on Ursa Live!`;
  }

  return (
    <StyledCard active={undefined}>
      <Typography
        variant="subtitle1"
        textAlign={'center'}
        fontWeight={'500'}
        lineHeight={'17px'}
        sx={{ color: (theme) => theme.palette.action.active }}
      >
        {formatDateTime(startTime || '', SHORT_DATE_TIME_WITH_TIME_ZONE)}
      </Typography>
      <Divider sx={{ my: 1.5 }} />
      <Typography
        textAlign={'center'}
        variant="body2"
        mb={1.25}
        fontSize={{ md: 12 }}
        fontWeight={'300'}
        lineHeight={'14px'}
      >
        Add to
      </Typography>
      <StyledCalendarBtnGroup alignItems={'center'} {...calendarBtnGroupProps}>
        <AddToCalendarButton
          name={eventName || ''}
          description={`Join the livestream at ${window.location.href}`}
          startDateTime={startTime || ''}
          endDateTime={endTime || ''}
          text="Apple Calendar"
          icon="apple"
          options={['Apple']}
          flexGrow={1}
          width={'100%'}
        />
        <AddToCalendarButton
          name={eventName || ''}
          description={`Join the livestream at ${window.location.href}`}
          startDateTime={startTime || ''}
          endDateTime={endTime || ''}
          text={'Google Calendar'}
          icon="google"
          options={['Google']}
          flexGrow={1}
          width={'100%'}
        />
      </StyledCalendarBtnGroup>
    </StyledCard>
  );
};

export default EventCard;
