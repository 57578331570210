import { ToastContainerProps } from 'react-toastify';

export const USER_TYPE = {
  ADMIN: 'ADMIN',
  ARTIST: 'ARTIST',
  FAN: 'FAN',
};
export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  NEW: 3,
};
export const EVENT_STATUS = {
  SCHEDULED: 1,
  STARTED: 2,
  END: 3,
};
export const toastConfig: ToastContainerProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const FULL_DATE_TIME_WITH_TIME_ZONE = 'DDDD t ZZZZ';
export const SHORT_DATE_TIME_WITH_TIME_ZONE = 'DDD t ZZZZ';
export const SHORT_TIME_WITH_TIME_ZONE = 't ZZZZ';
export const SHORT_DATE = 'DDD';
export const ON_BOARDING_INFO_STEP = {
  PROFILE: 'PROFILE',
  DONE: 'DONE',
};
export const POPUP_TYPE = {
  NONE: '',
  STAGE: 'stage',
  STAGE_INVITE: 'stage-invite',
  TIP: 'tip',
  FOLLOW: 'follow',
  REWARDS: 'rewards',
  PROFILE: 'profile',
  PAYMENT_METHODS: 'payment-methods',
  STRIPE_CHECKOUT: 'stripe-checkout',
  THANK_POPUP: 'thank-pop-up',
  WELCOME_POPUP: 'welcome',
  LOGIN: 'log-in',
  SIGN_UP: 'sign-up',
  FORGOT_PWD: 'forgot-pwd',
  RESET_PWD: 'reset-pwd',
  BUY_LC_POPUP: 'buy-lc-pop-up',
  EDIT_PROFILE_POPUP: 'edit-profile',
  MANAGE_EVENT: 'manage-event',
  WELCOME_NOTIFICATION: 'welcome-notification',
  BOOKING_PRODUCER: 'booking-producer',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_USERNAME: 'change-username',
  CHANGE_EMAIL: 'change-email',
  BECOME_PARTNERSHIP: 'become-partnership',
  UPDATE_PHONE_NUMBER: 'update-phone-number',
  SELECT_GENRE: 'select-genre',
  UPLOAD_AVATAR: 'upload-avatar',
  GETTING_STARTED: 'getting-started',
  NEW_WELCOME_NOTIFICATION: 'new-welcome-notification',
};

export type PopupTypKeys = keyof typeof POPUP_TYPE;
export type PopupTypeValues = (typeof POPUP_TYPE)[PopupTypKeys];

export const DEFAULT_EVENT_BUFFER_TIME = 60; // in minutes
export const DEFAULT_FAN_PROFILE_AVATAR = '/assets/images/defaultPic1.png';
export const DEFAULT_ARTIST_PROFILE_AVATAR = '/assets/images/artist-avtar.jpg';
export const WEB_SOCKET_PING_INTERVAL = 45000; // 1 minute
export const WEB_SOCKET_RECONNECT_INTERVAL = 1000;

export const FEATURE_FLAGS_TYPE = {
  STATISTIC_PAGE_VIEW: 'STATISTIC_PAGE_VIEW',
  STATISTIC_USED_FUNCTIONS: 'STATISTIC_USED_FUNCTIONS',
  BLOCKED_USER: 'BLOCKED_USER',
  SEARCH_ARTIST: 'SEARCH_ARTIST',
  LOADING_VIDEO_ANIMATION: 'LOADING_VIDEO_ANIMATION',
  SEARCH_ARTIST_FUNCTIONS: 'SEARCH_ARTIST_FUNCTIONS',
  REMINDER_TIPPING: 'REMINDER_TIPPING',
  SHOW_ATTENDEES_STATS: 'SHOW_ATTENDEES_STATS',
  SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW: 'SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW',
  ACTIVE_NEW_SOCKET_MANAGEMENT: 'ACTIVE_NEW_SOCKET_MANAGEMENT',
  ACTIVE_SHARE_PROFILE_ON_START_LIVE: 'ACTIVE_SHARE_PROFILE_ON_START_LIVE',
  NEW_PAYMENT_FLOW_FOR_TAX: 'NEW_PAYMENT_FLOW_FOR_TAX',
  ACTIVE_EMOJI_REACTION: 'ACTIVE_EMOJI_REACTION',
  SEPARATE_LIVE_COINS: 'SEPARATE_LIVE_COINS',
  PAYOUT_FEATURE: 'PAYOUT_FEATURE',
  BANNER_EVENT_ENHANCE: 'BANNER_EVENT_ENHANCE',
  HOMEPAGE_FEATURED_EVENT_NUMBER_ENHANCE:
    'HOMEPAGE_FEATURED_EVENT_NUMBER_ENHANCE',
  NEW_ARTIST_SIGN_UP_FLOW: 'NEW_ARTIST_SIGN_UP_FLOW',
};

export const GA4_PAGE_VIEW_CUSTOM_FIELD = {
  ACCOUNT_TYPE: 'account_type',
};
